import axios from "axios";
import { connection_string } from "./connection_strings";
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  clearLocalStorage,
} from "./local-storage";
const refreshTokenEndpoint = "/token/refresh";
axios.defaults.baseURL = connection_string;

const getDetailErrorMessage = (errorData) => errorData["detail"] || null;

const getErrorMessagesFromArray = (errorData) => {
  let errorMessage = "";
  for (const error of errorData) {
    errorMessage += `${error?.key}: ${error?.value}\n`;
  }
  return errorMessage;
};

const handleError = (error) => {
  const responseErrorData = error.response?.data;

  if (!responseErrorData) return "An error occurred.";

  if (Array.isArray(responseErrorData.error))
    return getErrorMessagesFromArray(responseErrorData.error);

  const detailErrorMessage = getDetailErrorMessage(responseErrorData);

  return (
    detailErrorMessage ||
    responseErrorData.error ||
    "Something went wrong, please try again."
  );
};

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    const skipTokenURLs= ["get-user?ut=", "/client/login", "/client/register", "corporate-client/register"]
    const shouldSkipToken = skipTokenURLs.some(url => config.url.includes(url));
    const token = getAccessToken();
    if (token && !shouldSkipToken) config.headers["Authorization"] = "Bearer " + token;
    return config;
  },
  function (error) {
    // Convert error to a string message
    const errorMessage =
      error?.message || "An error occurred during the request.";

    // Return a rejected promise with the error message
    return Promise.reject(errorMessage);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => response?.data,
  (error) => {
    const STATUS = error?.response?.status;
    const CODE = error?.code;
    const CONFIG = error?.config;

    if (
      STATUS === 403 ||
      (STATUS === 401 && CONFIG?.url == "/token/refresh") ||
      CODE === "ERR_NETWORK"
    ) {
      clearLocalStorage();
      window.location.href = "login";
      return Promise.reject(handleError(error));
    }

    if (STATUS === 401 && !CONFIG?._retry) {
      CONFIG._retry = true;
      const excludedUrls = ["/client/login", "/client/register", "corporate-client/register"];

      if (!excludedUrls.includes(CONFIG.url)) {
        const refreshToken = getRefreshToken();
        if (!refreshToken) window.location.href = `login`;
        return axios
          .post(refreshTokenEndpoint, {
            refresh: refreshToken,
          })
          .then((res) => {
            if (res?.access) {
              setAccessToken(res?.access);
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + getAccessToken();
              return axios(CONFIG);
            } else {
              throw new Error("No access token found!");
            }
          })
          .catch((error) => {
            clearLocalStorage();
            window.location.href = `login`;
            return Promise.reject(handleError(error));
          });
      }
    }
    return Promise.reject(handleError(error));
  }
);
