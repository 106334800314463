import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ErrorImg from "../../images/modal/failure.png";

const useStyles = makeStyles({
  okButton: {
    backgroundColor: "#3f51b5",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#303f9f",
    },
  },
});

export const SuccessModal = ({ open, handleClose, message }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="success-modal">
      <DialogTitle id="success-modal">Success</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className={classes.okButton}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ModalSuccess = ({ open, handleClose, children }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {children}
      </Modal>
    </div>
  );
};

export const ModalError = ({ open, handleClose, message }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: "12px",
    outline: "none",
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{}}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
          >
            <CloseIcon onClick={handleClose} />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
            }}
          >
            <img src={ErrorImg} alt="error" />
            <p>{message}</p>
            {/* <Button variant="contained" className={styles.btnStyles}>
              Try Again
            </Button> */}
          </div>
        </Box>
      </Modal>
    </div>
  );
};
