import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../dashboard-layout.module.css";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import logo from "../../../images/login/logo.png";
import DrawerList from "./drawer-list/drawerList";

const DrawerContent = ({isMobileScreen, handleDrawerStatus }) => {
  return (
    <div className={styles.root}>
      <Box>
        <div className={styles.logoWrap}>
          <img src={logo} alt="beme_logo" className={styles.logoStyles} />
          <div className={styles.mobileDrawerHandler}>
            <CloseIcon
              className={styles.hamIconStyles}
              onClick={handleDrawerStatus}
            />
          </div>
        </div>
      </Box>
      <DrawerList handleDrawerStatus={handleDrawerStatus} isMobileScreen={isMobileScreen} />

      <List className={styles.bottomTextCont}>
        <ListItem>
          <ListItemText>
            <p className={styles.bottomTextStyles}>Version 0.1</p>
            <p className={styles.bottomTextStyles}>Homeostasis Copyrights</p>
          </ListItemText>
        </ListItem>
      </List>
    </div>
  );
};

export default DrawerContent;
