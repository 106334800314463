import { IconButton, Typography } from "@mui/material";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import styles from "./notification-popover.module.css";
import { memo } from "react";

const NotificationPopoverHeader = memo(({ handleClose }) => (
  <div className={styles.container}>
    <IconButton
      onClick={handleClose}
      style={{ position: "absolute", left: "0.25rem" }}
    >
      <ArrowForwardRoundedIcon sx={{ color: "white" }} />
    </IconButton>
    <Typography className={styles.title}>NOTIFICATIONS</Typography>
  </div>
));

export default NotificationPopoverHeader;
