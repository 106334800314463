// styles.js

export const clientFormStyles = {
  dropDownInput: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    // background:"green",
    // width:"700px"
    
  },
  dateInput: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      background: "#f6f5f5",
    },
  },
  timeZoneInput: {
    "& .MuiPaper-root-MuiPopover-paper-MuiMenu-paper": {
      width: "100px",
      padding: "10px",
      right: "215px",
    },
  },
  radioBtnLabel: {
    "& .MuiTypography-root": {
      padding: "16px",
      display: "flex",
      flexFlow: "column",
      width: "490px",
      margin: "10px",
      marginTop: "10px",
      backgroundColor: "#e8e8e8 !important",
      borderRadius: "4px",
    },
    "& .Mui-checked + span": {
      color: "#3E5378 !important",
      fontWeight: "600",
    },
    "@media (max-width: 1200px)": {
      // Example media query for screens up to 768px wide
      "& .MuiTypography-root": {
        width: "420px", // Adjust styles for smaller screens
      },
    },
    "@media (max-width: 575px)": {
      // Example media query for screens up to 768px wide
      "& .MuiTypography-root": {
        width: "260px", // Adjust styles for smaller screens
      },
    },
  },
  passwordFeild: {
    "& .MuiInputBase-adornedEnd": {
      marginTop: "10px",
    }
  }
};
