import { configureStore } from "@reduxjs/toolkit";
import sliceReducer, { nextStep, prevStep, setStepData, clearStep1Data, resetSignup } from "./slice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage

const persistConfig = {
  key: "client_root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, sliceReducer);

export const store = configureStore({
  reducer: {
    slice: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: true,
});

export const persistor = persistStore(store);

// Export the new reducers to use in your components
export { nextStep, prevStep, setStepData, clearStep1Data, resetSignup };
