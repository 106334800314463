// icons
import dashboardIcon from "../../../../images/dashboard/icons/dashboard.png";
import contactusIcon from "../../../../images/dashboard/icons/contactus.png";
import profileIcon from "../../../../images/dashboard/icons/profile.png";
import appointmentIcon from "../../../../images/dashboard/icons/appointment.png";
import assignmentsIcon from "../../../../images/dashboard/icons/assignments.png";
import calendarIcon from "../../../../images/dashboard/icons/calendar.png"

export const drawerItems = [
  { title: "Dashboard", icon: dashboardIcon, route: "/dashboard" },
  { title: "Profile", icon: profileIcon, route: "/settings/profile" },
  {
    title: "Appointments",
    icon: appointmentIcon,
    route: "/appointment-list",
  },

  {
    title: "Calendar",
    icon: calendarIcon,
    route: "/calendar",
  },


  { title: "Assignments", icon: assignmentsIcon, route: "/assignments" },
  { title: "Contact Us", icon: contactusIcon, route: "/contact-us" },
];
