import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state";

const initialSignupState = {
  step: 0,
  step1Data: {
    city: "",
    confirm_password: "",
    country: "",
    state: "",
    email: "",
    emergency_contact: "",
    emergency_contact_name: "",
    emergency_contact_relationship: "",
    first_name: "",
    gender: "female",
    last_name: "",
    password: "",
    phone: "",
    reference_platform: "",
  },
  step2Data: {
    relationship_status: "",
  },
  step3Data: {
    children: "",
    consent_form: "",
  },
};

const combinedInitialState = {
  ...initialState,
  signup: initialSignupState,
};

export const slice = createSlice({
  name: "slice",
  initialState: combinedInitialState,
  reducers: {
    //setting user to store
    setUser: (state, action) => {
      state = { ...state, user: action.payload };
      return state;
    },
    setRecentProvider: (state, action) => {
      const objectToCheckId = action.payload.id;

      const foundObjIndex = state.upcomingProvider.findIndex(
        (provider) => provider.id === objectToCheckId
      );

      let updatedProviders;

      if (foundObjIndex !== -1) {
        // Use filter to remove the element immutably
        updatedProviders = state.upcomingProvider.filter(
          (provider) => provider.id !== objectToCheckId
        );
      } else {
        updatedProviders = [...state.upcomingProvider];
      }

      // Add the new or updated item
      updatedProviders.unshift(action.payload);

      return {
        ...state,
        upcomingProvider: updatedProviders,
      };
    },
    setUpcomingProvider: (state, action) => {
      state = { ...state, recentProvider: action.payload };
      return state;
    },
    nextStep: (state) => {
      state.signup.step += 1;
    },

    setNotifCount: (state, action) => {
      state = { ...state, notifCount: action.payload };
      return state;
    },

    prevStep: (state) => {
      state.signup.step -= 1;
    },

    setStepData: (state, action) => {
      const { step, data } = action.payload;
      if (step === 0) {
        return {
          ...state,
          signup: {
            ...state.signup,
            step1Data: { ...data },
          },
        };
      } else if (step === 1) {
        return {
          ...state,
          signup: {
            ...state.signup,
            step2Data: { ...data },
          },
        };
      } else if (step === 2) {
        return {
          ...state,
          signup: {
            ...state.signup,
            step3Data: { ...data },
          },
        };
      }
      return state;
    },

    clearStep1Data: (state) => {
      state.signup.step1Data = {
        // age: "",
        dob: "",
        city: "",
        confirm_password: "",
        country: "",
        state: "",
        state: "",
        email: "",
        emergency_contact: "",
        emergency_contact_name: "",
        emergency_contact_relationship: "",
        first_name: "",
        gender: "female",
        last_name: "",
        password: "",
        phone: "",
        reference_platform: "",
      };
    },

    clearAllData: (state) => {
      state.signup.step = 0;
      state.signup.step1Data = {
        // age: "",
        dob: "",
        city: "",
        confirm_password: "",
        country: "",
        email: "",
        emergency_contact: "",
        emergency_contact_name: "",
        emergency_contact_relationship: "",
        first_name: "",
        gender: "female",
        last_name: "",
        password: "",
        phone: "",
        reference_platform: "",
        state: "",
      };
      state.signup.step2Data = {
        relationship_status: "",
      };
      state.signup.step3Data = {
        children: "",
        consent_form: "",
      };
    },

    resetSignup: (state) => {
      state.signup = initialSignupState;
    },
    resetState: (state) => {
      state.upcomingProvider = [];
      state.user = {};
      state.notifCount = 0;
      state.recentProvider = {};
    },
  },
});

export const selectRecentProvider = (state) => {
  return state?.slice?.recentProvider;
};

// this is for dispatch
export const {
  setUser,
  setRecentProvider,
  nextStep,
  prevStep,
  setStepData,
  clearStep1Data,
  clearAllData,
  resetSignup,
  setNotifCount,
  resetState,
  setUpcomingProvider,
} = slice.actions;

export const selectNotifCount = (state) => state.slice?.notifCount;

export const selectUser = (state) => state.slice?.user;

// this is for configureStore
export default slice.reducer;
