import { Button } from "@material-ui/core";
import React, { useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import { Tabs, Tab, TextField } from "@material-ui/core";
import styles from "./login.module.css";
import { useNavigate, Link } from "react-router-dom";
import { useMutation } from "react-query";
import {
  setAccessToken,
  setCurrency,
  setIsCorporateClient,
  setIsScAnswered,
  setRefreshToken,
  setUserType,
} from "../../utils/local-storage";
import CircularProgress from "@material-ui/core/CircularProgress";
import loginValidationForm from "../../schema/clientLoginSchema";
import { Formik } from "formik";
import { networkRequest } from "../../utils/api";
import { SuccessModal } from "../../components/modals/Modal.Success";
import { InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { getProfile } from "../../services/profile";
import { setUser } from "../../redux/slice";
import { useDispatch } from "react-redux";
import { Fade } from "@mui/material";

const LoginPage = () => {
  const [profileLoading, setProfileLoading] = useState(false);
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [modalStatus, setModalStatus] = useState(false);
  const emailRef = useRef("");
  const [showPassword, setShowPassword] = useState(false);
  const closeModal = () => setModalStatus(false);
  const openModal = () => setModalStatus(true);
  const dispatch = useDispatch();

  const EmailText = {
    style: {
      textAlign: "center",
      color: "#3E5378",
      padding: "10px 0px 10px",
      fontSize: "1rem",
    },
  };

  const handleTabs = (e, val) => {
    setValue(val);
  };
  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const { mutate, isLoading, error } = useMutation(networkRequest, {
    onSuccess: async (response) => {
      const { refresh, access, type, is_sc_answered, currency, is_corporate_client } = response;
      if (type === "provider") {
        throw new Error("Cannot find any accounts with the given credentials")
          .message;
      }
      setProfileLoading(true);
      setIsCorporateClient(is_corporate_client)
      setAccessToken(access);
      setRefreshToken(refresh);
      setUserType(type);
      setIsScAnswered(is_sc_answered);
      setCurrency(currency);
      const userData = await getProfile();
      dispatch(setUser(userData?.data));
      setProfileLoading(false);
      if (!is_sc_answered && type === "client") {
        // navigate("/screening-questions", { replace: true });
        window.location.replace("/client/screening-questions");
        //save time_zone in ls
        return;
      }
      // if (is_sc_answered && type === "client") {
      //   navigate("/dashboard");
      //   return;
      // }
      if (is_sc_answered && type === "client") {
        navigate("/book-appointment");
        return;
      }
    },
    onError: (error) => {
      setProfileLoading(false);
      error?.includes(
        "OTP already Generated. Either enter the recent OTP sent to email or wait for it to expire. Estimated expiry 15 min"
      ) && openModal();
      error?.includes("User Account Not verified") && openModal();
    },
  });

  function gotoSignup() {
    navigate("/auth/signup");
  }

  const onSubmit = (values) => {
    mutate({
      method: "post",
      url: "/client/login",
      data: values,
    });
  };

  return (
    <>
      <SuccessModal
        open={modalStatus}
        message={
          "OTP already Generated. Either enter the recent OTP sent to email or wait for it to expire. Estimated expiry 15 min"
        }
        handleClose={() => {
          closeModal();
          navigate(`/auth/otp-signup/${emailRef.current}`);
        }}
      />
      <Fade in={true} timeout={300}>
        <div className={styles.formDiv}>
          <Tabs value={value} onChange={handleTabs} centered>
            <Tab className={styles.tab} label="SIGN IN" />
            <Tab className={styles.tab} label="SIGN UP" onClick={gotoSignup} />
          </Tabs>
          <Grid>
            <div align="center">
              <div className={styles.headlineMargin}>
                <div className={styles.secondHeading}> Hi, Welcome Back</div>
                <div className={styles.secondPara}>
                  Please login to your account
                </div>
              </div>
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                onSubmit={onSubmit}
                validationSchema={loginValidationForm}
              >
                {(props) => (
                  <form
                    onSubmit={props.handleSubmit}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault(); // Prevent form submission if Enter key is pressed
                        props.handleSubmit(); // Call the form's handleSubmit function
                      }
                    }}
                  >
                    <div className={styles.inputTextMargin}>
                      <TextField
                        id="email"
                        type={"email"}
                        label="Email"
                        fullWidth
                        value={props.values.email}
                        onChange={(e) => {
                          emailRef.current = e.target.value;
                          props.handleChange(e);
                        }}
                        onBlur={props.handleBlur}
                        helperText={
                          props?.touched.email ? props?.errors.email : ""
                        }
                        error={
                          props?.touched.email && Boolean(props?.errors.email)
                        }
                        inputProps={EmailText}
                        InputLabelProps={{
                          disableAnimation: true,
                        }}
                      />
                    </div>
                    <div className={styles.inputTextMargin}>
                      <TextField
                        id="password"
                        fullWidth
                        value={props.values.password}
                        onChange={props.handleChange}
                        autoComplete="new-password"
                        onBlur={props.handleBlur}
                        helperText={
                          props?.touched.password ? props?.errors.password : ""
                        }
                        error={
                          props?.touched.password &&
                          Boolean(props?.errors.password)
                        }
                        type={showPassword ? "text" : "password"}
                        label="Password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleShowPasswordClick}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          style: { color: "#525254", fontFamily: "arial" },
                          inputProps: {
                            autoComplete: "new-password",
                            form: {
                              autocomplete: "off",
                            },
                            style: {
                              textAlign: "center",
                              color: "#5e5e5e",
                              padding: "10px 0px 10px 42px",
                              fontSize: ".95rem",
                            },
                          },
                        }}
                        InputLabelProps={{
                          disableAnimation: true,
                        }}
                      />
                      <div className={styles.forgetBtn}>
                        <Button
                          component={Link}
                          to="/auth/forgot-password"
                          type="submit"
                          style={{
                            textTransform: "capitalize",
                            color: "#3E5378",
                          }}
                        >
                          Forgot Password?
                        </Button>
                      </div>
                    </div>

                    <div className={styles.errorMsg}>{error}</div>
                    <div>
                      <Button
                        className={styles.loginBtn}
                        type="submit"
                        variant="contained"
                      >
                        {isLoading || profileLoading ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Sign In"
                        )}
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </Grid>
        </div>
      </Fade>
    </>
  );
};

export default LoginPage;
