import { Box, Button, Tooltip } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationPopover from "../../../../components/notification-popover";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import styles from "./dashboard-header-content.module.css";
import { useDispatch, useSelector } from "react-redux";
import avatar from "../../../../images/dashboard/avatar.png";
import { resetState, selectUser } from "../../../../redux/slice";
import { ModalSuccess } from "../../../../components/modals/Modal.Success";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from '@mui/icons-material/Logout';
import { clearLocalStorage } from "../../../../utils/local-storage";

const DashboardHeaderContent = ({ handleDrawerStatus }) => {
  const user = useSelector(selectUser);
  const location = useLocation();
  const navigate = useNavigate();
  const [headerTitle, setHeaderTitle] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const handleClose = () => setOpenSuccessModal(false);
  const handleLogout = () => setOpenSuccessModal(true);
  const dispatch = useDispatch();

  const handleYes = () => {
    clearLocalStorage();
    navigate("/auth/login");
    dispatch(resetState());
  };

  useEffect(() => {
    const pathname = location.pathname;
    let updatedPath = pathname.split('/');
    if(titleMap[updatedPath[updatedPath?.length - 1]]){
      const title = titleMap[updatedPath[updatedPath?.length - 1]] || "";
      setHeaderTitle(title);
      return;
    }
    if(titleMap[updatedPath[updatedPath?.length - 2]]){
      const title = titleMap[updatedPath[updatedPath?.length - 2]] || "";
      setHeaderTitle(title);
      return;
    }
    if(titleMap[updatedPath[updatedPath?.length - 3]]){
      const title = titleMap[updatedPath[updatedPath?.length - 3]] || "";
      setHeaderTitle(title);
      return;
    }
   
  }, [location.pathname]);
  
  return (
    <>
      <div className={styles.parent}>
        <ModalSuccess open={openSuccessModal} handleYes={handleYes}>
          <Box className={styles.areYouSureModal}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}
            >
              <CloseIcon onClick={handleClose} />
            </div>
            <p className={styles.text2Styles}>
              Are you sure you want to logout?
            </p>
            <div>
              <div className={styles.btnsCont}>
                <Button className={styles.yesBtnStyles} onClick={handleYes}>
                  Yes
                </Button>
                <Button className={styles.noBtnStyles} onClick={handleClose}>
                  No
                </Button>
              </div>
            </div>
          </Box>
        </ModalSuccess>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
            <MenuIcon
              className={styles.hamIconStyles}
              onClick={handleDrawerStatus}
            />
          <h2 className={styles.dashboardTextStyles}>
            {headerTitle || "Dashboard"}
          </h2>
        </div>
      </div>

      <Box className={styles.box}>
        <Box className={styles.chatAndForwardWrapperCont}>
          <NotificationPopover />
        </Box>
        <Box
          className={styles.chatAndForwardWrapperCont}
          onClick={handleLogout}
        >
          <Tooltip title={<h1 style={{ fontSize: "11px" }}>Logout</h1>}>
            {/* <img src={forwardIcon} alt="forward" /> */}
            <LogoutIcon sx={{ color: '#717171' }} />
          </Tooltip>
        </Box>
        <Box className={styles.avatarWrapper}>
          <Tooltip title={<h1 style={{ fontSize: "11px" }}>Profile</h1>}>
            <img
              src={user?.image_url || avatar}
              alt="avatar"
              className={styles.avatar}
              onClick={() => navigate("/settings/profile")}
            />
          </Tooltip>
        </Box>
      </Box>
    </>
  );
};

export default DashboardHeaderContent;


export const titleMap = {
  'profile': "My Profile",
  'password': "Password",
  'notifications': "Notifications",
  "payment-details": "Payment Details",
  "billing-invoice": "Billing Invoice",
  "appointment-list": "Appointments",
  "book-appointment": "Book Appointment",
  "patient-session": "Patient Session",
  "reschedule-appointment": "Reschedule Appointment",
  "change-provider": "Change Provider",
  "dashboard-provider": "Dashboard",
  'assignments': "Assignments",
  'calendar': "Calendar",
  "contact-us": "Contact Us",
  "privacy-policy": "Privacy Policy",
  "terms-and-condition": "Terms And Condition",
  "time-slots": "Time Slots",
  'booked': "Tentative Booked",
  'task-session': "Assignments",
  "journal": "Journal",
  "dashboard": "Dashboard",
  "corporate-booked": "Appointment Booked",
  "provider-details":"Provider Details"
};