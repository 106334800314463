import axios from "axios";

const createConfig = (config) => {
  try {
    // Check for valid 'config' object and required properties
    if (!config || typeof config !== "object")
      throw new Error("Invalid config object");
    if (!config.url) throw new Error("URL is missing");
    if (!config.method) throw new Error("Method is missing");

    // Serialize query parameters for 'get' requests
    let params = "";
    if ((config.method === "get" || config.method === "post") && config.params) {
      if (typeof config.params != "object")
        throw new Error("Invalid params object");
      for (const key in config.params) {
        params += `${key}=${config.params[key]}&`;
      }
    }

    // Configure the request with default or provided values
    const apiConfig = {
      method: config.method,
      url: params ? `${config.url}?${params}` : config.url,
    };

    if(config.headers) apiConfig.headers = config.headers
    // Handle request body for 'post', 'put', or 'patch' requests
    if (["post", "put", "patch"].includes(config.method)) {
      // Check for valid data and handle FormData if requested
      if (!config.data || typeof config.data !== "object")
        throw new Error("Invalid payload");
      if (config.formData) {
        const formData = new FormData();
        for (const key in apiConfig.data) {
          formData.append(key, config.data[key]);
        }

        apiConfig.data = formData;
      } else apiConfig.data = config.data;
    }

    return apiConfig;
  } catch (error) {
    throw error.message || "Configuration Error.";
  }
};

/**
 * Make a network request using Axios with customizable configuration.
 * @param {object} config - Configuration object for the network request.
 * @property {string} config.url - The URL for the API endpoint.
 * @property {string} config.method' - The HTTP request method (e.g., 'get', 'post', 'put', 'delete').
 * @property {object} [config.params] - Query parameters to include in the URL for 'get' requests.
 * @property {object} [config.data] - Data to send in the request body for 'post', 'put', or 'patch' requests.
 * @property {boolean} [config.formData=false] - Set to true if 'config.data' is a FormData object.
 * @returns {Promise} - A Promise that resolves with the Axios response or rejects with an error.
 * @throws {Error} - Throws an error if the 'config' object is invalid or required properties are missing.
 */

export const networkRequest = async (config) => {
  try {
    // Make the Axios request and return the result
    return await axios(createConfig(config));
  } catch (error) {
    throw error;
  }
};
