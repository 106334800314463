const ACCESS_TOKEN_NAME = "client_access_token";
const REFRESH_TOKEN_NAME = "client_refresh_token";
const USER_TYPE = "client_user_type";
const IS_SC_ANSWERED = "IS_SC_ANSWERED";
const CURRENCY = "currency";
const IS_CORPORATE_CLIENT = 'IS_CORPORATE_CLIENT';

export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_NAME);

export const removeAccessToken = () =>
  localStorage.removeItem(ACCESS_TOKEN_NAME);


export const getIsCorporateClient = () => localStorage.getItem(IS_CORPORATE_CLIENT);
export const setIsCorporateClient = (val) => 
  localStorage.setItem(IS_CORPORATE_CLIENT, val)

export const removeIsCorporateClient = () =>
  localStorage.removeItem(IS_CORPORATE_CLIENT);


export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_NAME);

export const setAccessToken = (accessToken) => {
  localStorage.setItem(ACCESS_TOKEN_NAME, accessToken);
  // localStorage.setItem(REFRESH_TOKEN_NAME, token);
};

export const setRefreshToken = (refreshToken) => {
  localStorage.setItem(REFRESH_TOKEN_NAME, refreshToken);
};

export const setUserType = (userType) => {
  localStorage.setItem(USER_TYPE, userType);
};

export const getUserType = (userType) => {
  return localStorage.getItem(USER_TYPE, userType);
};

export const getIsScAnswered = () => {
  return localStorage.getItem(IS_SC_ANSWERED);
};

export const setIsScAnswered = (isAnswered) => {
  localStorage.setItem(IS_SC_ANSWERED, isAnswered);
};

export const setCurrency = (currency) => {
  localStorage.setItem(CURRENCY, currency);
};

export const clearLocalStorage = () => {
  for (let key in localStorage) {
    if (
      [
        ACCESS_TOKEN_NAME,
        REFRESH_TOKEN_NAME,
        USER_TYPE,
        IS_SC_ANSWERED,
        "persist:client_root",
      ].some((k) => k === key)
    ) {
      localStorage.removeItem(key);
    }
  }
};
