import { Badge, Popover } from "@mui/material";
import { useCallback, useState } from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationPopoverContent from "./NotificationPopoverContent";
import useNotificationUpdates from "../../hooks/useNotificationUpdates";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

const NotificationPopover = () => {
  const [notifCount] = useNotificationUpdates();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <Badge color="primary" badgeContent={notifCount}></Badge>
      <Tooltip title={<h1 style={{ fontSize: "11px" }}>Notifications</h1>}>
        <IconButton disableRipple onClick={handleClick} sx={{width:'100%',height:'100%'}}>
          <NotificationsIcon
            style={{ fontSize: "1.5rem" }}
          />
        </IconButton>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <NotificationPopoverContent handleClose={handleClose} />
      </Popover>
    </>
  );
};

export default NotificationPopover;
