import { useDispatch, useSelector } from "react-redux";
import { selectNotifCount, setNotifCount } from "../redux/slice";
import { useQuery } from "react-query";
import { networkRequest } from "../utils/api";

const useNotificationUpdates = (refetchInterval = 20000) => {
  const dispatch = useDispatch();
  const notifCount = useSelector(selectNotifCount);

  const onNotificationFetched = (response) => {
    const count = response?.notification_count
    if (typeof count === "number" && count !== notifCount)
      dispatch(setNotifCount(count));
  };

  useQuery(
    ["SELECT_NOTIFICATION_COUNT"],
    () =>
      networkRequest({
        method: "get",
        url: "/session/notification?notif_count=True",
      }),
    {
      onSuccess: onNotificationFetched,
      refetchInterval,
    }
  );

  return [notifCount];
};

export default useNotificationUpdates;
