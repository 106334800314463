import { Badge, Button, Divider, Typography } from "@mui/material";
import { memo } from "react";
import Loader from "../loader";
import moment from "moment";
import NotificationPopoverHeader from "./NotificationPopoverHeader";
import styles from "./notification-popover.module.css";
import useInfiniteNotifications from "../../hooks/useInfiniteNotifications";
import { useNavigate } from "react-router-dom";

const NotificationPopoverContent = memo(({ handleClose }) => {
  const {
    data: notifications,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteNotifications();
  const navigate = useNavigate();

  const redirect = (notification) => {
    if (notification.type === "session") navigate(`/appointment-list`);

    if (notification.type === "task")
      navigate(
        notification?.type_id
          ? `/assignments/task/${notification.type_id}`
          : `/assignments`
      );
  };

  return (
    <div className={styles.contentContainer}>
      <NotificationPopoverHeader handleClose={handleClose} />
      <div style={{ paddingTop: "4rem" }}>
        {isLoading ? (
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        ) : notifications?.length > 0 ? (
          <div>
            {notifications?.map((notification, index) => (
              <div key={notification?.id}>
                <div
                  className={styles.notificationItem}
                  onClick={() => redirect(notification)}
                >
                  <div className={styles.notificationContent}>
                    <div>
                      <Typography
                        className={styles.notificationText}
                        sx={{
                          fontWeight:
                            notification?.is_seen === false ? 800 : "",
                        }}
                      >
                        {notification?.description}
                      </Typography>
                      <Typography className={styles.notificationTime}>
                        {moment(notification?.created_at).fromNow()}
                      </Typography>
                    </div>
                    {!notification?.is_seen && (
                      <Badge
                        className={styles.dotBadge}
                        color="success"
                        badgeContent=" "
                        variant="dot"
                      />
                    )}
                  </div>
                </div>
                {index < notifications?.length - 1 && <Divider light />}
              </div>
            ))}
            {hasNextPage && (
              <Button
                style={{ width: "100%" }}
                disabled={isFetchingNextPage}
                onClick={fetchNextPage}
              >
                {isFetchingNextPage ? <Loader size={"20px"} /> : "LOAD MORE"}
              </Button>
            )}
          </div>
        ) : (
          <Typography className={styles.emptyComponent}>
            No notifications
          </Typography>
        )}
      </div>
    </div>
  );
});

export default NotificationPopoverContent;
