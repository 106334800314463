import { useInfiniteQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { selectNotifCount, setNotifCount } from "../redux/slice";
import { useMemo } from "react";
import { useEffect } from "react";
import { networkRequest } from "../utils/api";

const useInfiniteNotifications = () => {
  const dispatch = useDispatch();
  const notifCount = useSelector(selectNotifCount);
  const getNextPageParam = (lastPage, allPages) =>
    lastPage?.next ? allPages.length + 1 : undefined;
  const onNotificationFetched = () => dispatch(setNotifCount(0));

  const {
    data,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    "GET_NOTIFICATIONS",
    ({ pageParam = 1 }) =>
      networkRequest({
        method: "get",
        url: "/session/notification",
        params: {
          page: pageParam,
          page_size: 10,
        },
      }),
    {
      getNextPageParam,
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
      onSuccess: onNotificationFetched,
    }
  );
  const mergedPages = useMemo(
    () => data?.pages?.flatMap((page) => page?.results),
    [data]
  );

  useEffect(() => {
    notifCount > 0 && refetch();
  }, [notifCount]);

  return {
    data: mergedPages,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  };
};

export default useInfiniteNotifications;
