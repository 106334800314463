import { useLocation, useNavigate } from "react-router-dom";
import { getAccessToken, getIsScAnswered } from "../../utils/local-storage";
import { Suspense, useEffect } from "react";
import DashboardLayout from "../../layout/dashboard-layout";
import FallbackLoader from "../../components/fallback-loader";
export default function PrivateRoutes({ children, withoutDashboardLayout = false }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isScAnswered = JSON.parse(getIsScAnswered());
  let token = getAccessToken();
  useEffect(() => {
    if (!token) navigate("/auth/login", { replace: true });
    if (!isScAnswered) navigate("/screening-questions", { replace: true });
    if (isScAnswered && pathname === "/screening-questions")
      navigate("/dashboard", { replace: true });
  }, [token, isScAnswered, navigate]);

  if (withoutDashboardLayout)
    return <>{children}</>;


  return <DashboardLayout>
    <Suspense fallback={<FallbackLoader />}>
      <>
        {children}
      </>
    </Suspense>
  </DashboardLayout>
}
