import { Box, CircularProgress, Grid } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import logo from "../../images/login/logo.png";
import service from "../../images/login/services.png";
import otpImg from "../../images/otp/otp.png";
import img3 from "../../images/login/img3.png";
import img4 from "../../images/login/img4.png";
import styles from "./auth-layout.module.css";
import { Suspense } from "react";

const FallbackLoader = () => {
  return (
    <Box
      sx={{
        background: "white",
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress sx={{ color: "#2f4858" }} />
    </Box>
  );
};

const AuthLayout = ({ children }) => {
  return (
    <Grid container className={styles.mainGrid}>
      <Grid item md={6} sm={12} className={styles.sideBar}>
        <div className={styles.sideBarDiv}>
          <img className={styles.logo} src={logo} alt="logo" />
          <h1 className={styles.mainHeading}>Behavioral and Mental Health</h1>

          <div className={styles.sliderDiv}>
            <Swiper
              slidesPerView={"auto"}
              centeredSlides={true}
              spaceBetween={100}
              modules={[Autoplay, Pagination]}
              pagination={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              className="mySwiper"
            >
              <SwiperSlide>
                <p className={styles.introPara}>
                  You are not your illness. You have an individual story to
                  tell. You have a name, a history, a personality. Staying
                  yourself is part of the battle.
                </p>
                <img className={styles.img1} src={service} alt="service" />
              </SwiperSlide>
              <SwiperSlide>
                <p className={styles.introPara}>
                  It is during our darkest moments that we must focus to see the
                  light.
                </p>
                <img className={styles.img2} src={otpImg} alt="service" />
              </SwiperSlide>
              <SwiperSlide>
                <p className={styles.introPara}>
                  There is hope, even when your brain tells you there isn’t.
                </p>
                <img className={styles.img3} src={img3} alt="service" />
              </SwiperSlide>
              <SwiperSlide>
                <p className={styles.introPara}>
                  Life doesn’t make any sense without interdependence. We need
                  each other, and the sooner we learn that, the better for us
                  all.
                </p>
                <img className={styles.img3} src={img4} alt="service" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </Grid>
      <Grid md={6} sm={12} className={styles.formGrid}>
        <Suspense fallback={<FallbackLoader />}>{children}</Suspense>
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
