import { Box, CssBaseline, Drawer, Grid } from "@mui/material";
import { Suspense, useEffect, useState } from "react";
import DrawerContent from "./components/DrawerContent";
import styles from "./dashboard-layout.module.css";
import DashboardHeader from "./components/dashboard-header";
import FallbackLoader from "../../components/fallback-loader";

const DRAWER_WIDTH = 300;

const DashboardLayout = ({ children }) => {
  const [openDrawer, setOpenDrawer] = useState(window.innerWidth > 1199);
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth > 1199);

  useEffect(() => {
    const handleResize = () => {
      setOpenDrawer(window.innerWidth > 1199);
      setIsMobileScreen(window.innerWidth > 1199);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDrawerStatus = () => setOpenDrawer(!openDrawer);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DashboardHeader
        handleDrawerStatus={handleDrawerStatus}
        openDrawer={openDrawer}
      />
      <Box
        sx={{
          width: { lg: openDrawer ? DRAWER_WIDTH : null },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          hideBackdrop={true}
          variant="persistent"
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: DRAWER_WIDTH,
            },
          }}
          open={openDrawer}
        >
          <DrawerContent isMobileScreen={isMobileScreen} handleDrawerStatus={handleDrawerStatus} />
        </Drawer>
      </Box>
      <Grid container>
          <Grid item md={12} xs={12} className={styles.mainContent}>
            <Suspense fallback={<FallbackLoader />}>
              <>{children}</>
            </Suspense>
          </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardLayout;
