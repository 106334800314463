import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useState } from "react";
import ThemeContext from "../context/ThemeContext";
import { lightTheme, darkTheme } from "../theme/theme";

const ThemeProviderWrapper = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode((prev) => !prev);
  };

  const theme = createTheme(isDarkMode ? darkTheme : lightTheme);

  return (
    <ThemeContext.Provider
      value={{
        isDarkMode,
        toggleTheme,
        theme: isDarkMode ? darkTheme : lightTheme,
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProviderWrapper;
