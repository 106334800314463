import { lazy } from "react";

export const debounce = (func, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const lazyWithDelay = (cb, delay = 200) => lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(cb()), delay);
  });
}) 