import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { drawerItems } from "./constants";
import { Link } from "react-router-dom";
import styles from "./drawerList.module.css";
// import { useStyles } from "./styles"

const DrawerList = ({isMobileScreen, handleDrawerStatus}) => {
  const handleDrawer = () => {
    if(isMobileScreen) return;
      handleDrawerStatus()
  };

  return (
    <div className="drawer-container">
      <List className={styles.providerListStyles}>
        {drawerItems.map((listItem, idx) => (
          <Link to={listItem.route} key={idx} onClick={() => handleDrawer()} className="text-decor-none">
            <ListItem
              key={idx}
              className={styles.listItem}
              sx={{
                padding: 0,
                "& .MuiButtonBase-root": {
                  paddingLeft: "16px",
                },
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <img src={listItem.icon} alt={listItem.altText} />
                </ListItemIcon>
                <ListItemText>
                  <p className={styles.fontStyles}>{listItem.title}</p>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );
};

export default DrawerList;
