import { createTheme } from "@material-ui/core/styles";

//light theme:
export const lightTheme = createTheme({
  palette: {
    mode: "light", //set the initial mode to light
    primary: {
      main: "#FFFFFF", //white
      light: "#E8E8E8", //gray
      dark: "#3E5378", //blue
    },
    secondary: {
      main: "#3E5378", //green
      light: "#F8B849", //yellow
    }
  },
  typography: {
    fontFamily: ["Open Sans"],
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      size1600: 1600,
      size1450: 1450,

    },
  },
});

// dark theme:
export const darkTheme = createTheme({
  palette: {
    mode: "dark", // set the mode to dark
    primary: {
      main: "#3E5378",
    },
    secondary: {
      main: "#f48fb1",
    },
    background: {
      default: "#303030",
      paper: "#424242",
    },
    sideBarTextColor: {
      main: "green",
    },
  },
});
