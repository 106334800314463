import React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import { FormHelperText, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import styles from "./clientform.module.css";
import { clientFormStyles } from "./styles";


const Password = ({
  value,
  onChange,
  error,
  id,
  placeholder,
  label,
  touch
}) => {

    const [showPassword, setShowPassword] = React.useState(false);

    const handleShowPasswordClick = () => {
        setShowPassword(!showPassword);
      };
  return (
    <div>
      <div>
        <p className={styles.feildLabel}>{label}</p>
      </div>
      <Paper className={styles.otherInput}>
        <InputBase
          id={id}
          placeholder={placeholder}
          type={showPassword ? "text" : "password"}
          value={value}
          onChange={onChange}
          sx={clientFormStyles.passwordFeild}
          endAdornment={
            <InputAdornment position="end" >
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleShowPasswordClick}
                edge="end"
                style={{ marginRight: "2px"}}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </Paper>
      {error && touch && (
        <FormHelperText error sx={{ marginLeft: "10px" }}>
          {error}
        </FormHelperText>
      )}
    </div>
  );
};

export default Password;
