import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function FallbackLoader() {
  const height = window.innerHeight;
  return (
    <Box
      sx={{
        background: "#E8E8E8",
        height: `${height}px`,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress sx={{ color: "#2f4858" }} />
    </Box>
  );
}
