import Toolbar from "@mui/material/Toolbar";
import { useMediaQuery } from "@mui/material";
import React from "react";
import AppBar from "@mui/material/AppBar";
import styles from "./dashboardheader.module.css";
import DashboardHeaderContent from "../dashboard-header-content";
const drawerWidth = 300;

const DashboardHeader = ({ handleDrawerStatus, openDrawer, header }) => {
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <AppBar
      position="fixed"
      className={styles.appBar}
      sx={{
        width: { lg: openDrawer ? `calc(100% - ${drawerWidth}px)` : "100%" },
      }}
    >
      <Toolbar
        sx={{
          justifyContent: matches ? "space-between" : "space-between",
          get border() {
            return this._border;
          },
          set border(value) {
            this._border = value;
          },
        }}
        className={styles.toolBar}
      >
        <DashboardHeaderContent
          handleDrawerStatus={handleDrawerStatus}
          header={header}
        />
      </Toolbar>
    </AppBar>
  );
};

export default DashboardHeader;
