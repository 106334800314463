import { isPossiblePhoneNumber } from "react-phone-number-input";
import * as yup from "yup";

const today = new Date();
const maxDate = new Date(
  today.getFullYear() - 18,
  today.getMonth(),
  today.getDate()
);

const ClientFormValidationSchema = yup.object().shape({
  first_name: yup
    .string()
    .min(3, "First name must be at least 3 characters.")
    // .matches(/^[A-Za-z\'\-]+$/, "Please enter valid first name")
    .required("Please enter your first name."),

  last_name: yup
    .string()
    .min(3, "Last name must be at least 3 characters.")
    // .matches(/^[A-Za-z\'\-]+$/, "Please enter valid last name")
    .required("Please enter your last name."),
  email: yup
    .string()
    .email("Please enter a valid email address.")
    .required("Email is required."),
  phone: yup
    .string()
    .required("Phone number is required.")
    .max(15)
    .test("is-valid-phone-number", "Phone number invalid.", (value) => {
      return isPossiblePhoneNumber(value);
    }),
  dob: yup
    .date()
    .max(maxDate, "You must be 18 years or older")
    .required("Date of birth is required"),
  country: yup.string().required("Please enter your country."),
  city: yup.string().required("Please enter your city."),
  state: yup.string().required("Please enter your state."),
  emergency_contact_name: yup
    .string()
    // .matches(/^[A-Za-z\'\-]+$/, "Please enter valid emergency contact name")
    .required("Please enter the name of your emergency contact."),
  reference_platform: yup
    .string()
    // .matches(/^[A-Za-z\'\-]+$/, "Please enter valid platform")
    .required("Please enter the platform where you learned about us."),

  emergency_contact: yup
    .string()
    .required("Please enter the phone number of your emergency contact.")
    .max(15)
    .test("is-valid-phone-number", "Phone number invalid.", (value) => {
      return isPossiblePhoneNumber(value);
    }),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters long.")
    .required("Password is required."),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match.")
    .required("Please confirm your password."),

  emergency_contact_relationship: yup
    .string()
    .required("Please select emergency contact relationship."),
});

export const RelationshipStatusValidationSchema = yup.object().shape({
  //text-fields
  relationship_status: yup
    .string()
    .required("Please select your relationship status."),
});

export const ChildrenValidationSchema = yup.object().shape({
  // text-fields
  children: yup.string().required("Please select your number of children."),
  consent_form: yup
    .boolean()
    .oneOf([true], "Please check the consent form to proceed.")
    .required("Please check the consent form to proceed."),
});

export default ClientFormValidationSchema;
